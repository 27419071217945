<template>
  <div class="contents users" v-loading="loading">
    <div class="title flexB">
      <h2>회원 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option disabled selected value>선택</option>
          <option value="accountId">아이디</option>
          <option value="mobileNo">전화번호</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">회원 리스트</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 25%" />
          <col style="width: 25%" />
          <col style="width: 30%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>구분</th>
          <th>아이디</th>
          <th>전화번호</th>
          <th>가입일</th>
        </tr>
        <tr v-for="(data, i) in userList" :key="i">
          <td>
            <router-link to @click.native="handleDetail(data._id)">
              {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}
            </router-link>
          </td>
          <td>
            {{
              data.status == "WITHDRAW"
                ? "탈퇴"
                : data.status == "DROP"
                ? "정지"
                : data.isAdmin
                ? "관리자"
                : "일반"
            }}
          </td>
          <td>
            <router-link to @click.native="handleDetail(data._id)">{{
              data.status == "WITHDRAW" ? "탈퇴회원" : data.accountId
            }}</router-link>
          </td>
          <td>{{ data.mobileNo }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm:ss") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="userList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { deleteUser } from "@/api/index";
import { fetchUserList } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "users",
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      userList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  mounted() {
    // this.loading = true;
    this.getUserList();
  },
  methods: {
    handleDetail(id) {
      this.$router.push({ name: "userDetail", query: { id: id } });
    },
    handleDeleteUser(id) {
      let result = confirm("회원을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteUser(id).then((res) => {
        if (res.status == 200) {
          this.getUserList();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.userList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getUserList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        return alert("검색 조건을 선택해 주세요.");
      }
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$router.push({ name: "users", query: { page: val } });
      this.getUserList();
    },
    getUserList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.userList = res.data.users;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.currentPage = res.data.page;
          this.loading = false;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
  },
};
</script>
